@import '../../colors.scss';

.titleContainer {
    pointer-events: none;
    z-index: 1;
    position: relative;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: $darkBackground;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: $defaultText;
    width: 100%;
    font: bold 30px arial;
}

.text {
    pointer-events: none;
    z-index: 1;
    display: flex;
    position: absolute;
    color: $darkBackground;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $defaultText;
    width: 55%;
    margin-left: 5%;
    font: bold 28px arial;
}

.icon {
    pointer-events: all;
    position: absolute;
    width: 10%;
    top: 100%;
}

.frame {
    position: relative;
    left: 65%;
    top: 20%;
}

@import '../../colors.scss';

.backgroundContainer {
    z-index: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
}

.text {
    pointer-events: none;
    z-index: 1;
    display: flex;
    position: absolute;
    color: $darkBackground;
    text-shadow:
        -1px -1px 0 #000,
        1px -1px 0 #000,
        -1px 1px 0 #000,
        1px 1px 0 #000;
    width: 35%;
    margin-top: 5%;
    margin-left: 5%;
    font: bold 30px arial;
}

.icon {
    pointer-events: all;
    position: absolute;
    width: 10%;
    left: 0;
    top: 100%;
}

.gameIcon {
    pointer-events: all;
    position: absolute;
    width: 20%;
    left: 10%;
    top: 100%;
}

.embeddedPlay {
    position: absolute;
    width: 40%;
    height: 50%;
    left: 50%;
    top: 20%;
    z-index: 1;
}

.titleContainer {
    pointer-events: none;
    text-align: center;
    width: auto;
    height: auto;
}

.title {
    width: 40%;
}

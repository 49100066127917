@import '../../colors.scss';

.title {
    width: 100%;
}

.text {
    z-index: 1;
    display: flex;
    position: relative;
    color: $darkBackground;
    text-shadow:
        -1px -1px 0 #000,
        1px -1px 0 #000,
        -1px 1px 0 #000,
        1px 1px 0 #000;
    width: 100%;
    font: bold 30px arial;
}

.icon {
    position: absolute;
    width: 3vh;
    top: 100%;
    left: 20%;
}

.videoContainer {
    position: relative;
    width: 100%;
    height: 50vh;
}

.video {
    width: 80%;
    height: 80%;
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
}

.receiveContainer-enter {
    opacity: 0;
}

.receiveContainer-enter-active {
    opacity: 1;
    transition: opacity 1500ms;
}

.receiveContainer-exit {
    opacity: 1;
}

.receiveContainer-exit-active {
    opacity: 0;
    transition: opacity 1500ms;
}

@import '../../colors.scss';

.title {
    width: 80%;
    margin-left: 10%;
}

.text {
    z-index: 1;
    display: flex;
    position: relative;
    color: $darkBackground;
    text-shadow:
        -1px -1px 0 #000,
        1px -1px 0 #000,
        -1px 1px 0 #000,
        1px 1px 0 #000;
    width: 100%;
    text-align: center;
    font: bold 30px arial;
}

.icon {
    position: absolute;
    width: 4vh;
    top: 100%;
    left: 3vh;
}

.gameIcon {
    position: absolute;
    width: 6vh;
    top: 100%;
    left: 7vh;
}

.gameContainer {
    position: relative;
    width: 100%;
    height: 50vh;
}

.game {
    width: 80%;
    height: 60%;
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translateX(-50%);
}

@import '../../colors.scss';

.backgroundContainer {
    z-index: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
}

.title {
    pointer-events: none;
    z-index: 1;
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: center;
    font: bold 50px arial;
    color: $background;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: $defaultText;
}

.text {
    background-color: rgba(185, 143, 174, 0.5);
    border-radius: 2%;
    font: bold 30px arial;
    color: $defaultText;
}

.textContainer {
    margin-left: 5%;
    pointer-events: none;
    z-index: 1;
    display: flex;
    position: absolute;
    width: 35%;
}

.icon {
    pointer-events: all;
    position: absolute;
    width: 10%;
    left: 0;
    top: 100%;
}

.appIcon {
    pointer-events: all;
    position: absolute;
    width: 10%;
    left: 12%;
    top: 100%;
}

.demo {
    position: absolute;
    width: 40%;
    height: 50%;
    left: 50%;
    top: 20%;
    z-index: 1;
}

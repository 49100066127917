@import '../../../colors.scss';

.container {
    width: 30%;
    height: 30vh;
    position: relative;
    justify-content: center;
    text-align: center;
    vertical-align: center;
}

.container:hover .text {
    text-shadow:
        -1px -1px 0 $hoverText,
        1px -1px 0 $hoverText,
        -1px 1px 0 $hoverText,
        1px 1px 0 $hoverText,
        0 0 3px $hoverText;
}

.icon {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.text {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-60%);
    position: absolute;
    color: $darkBackground;
    font-size: 3vh;
    font-weight: 900;
    text-shadow:
        -1px -1px 0 $defaultText,
        1px -1px 0 $defaultText,
        -1px 1px 0 $defaultText,
        1px 1px 0 $defaultText;
}

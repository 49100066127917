@import '../../colors.scss';

.backgroundContainer {
    top: 0%;
    z-index: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
}

.imgContainer {
    z-index: 0;
    display: flex;
    width: 100%;
    height: 100vh;
    background-size: contain;
    position: absolute;
}

.titleContainer {
    pointer-events: none;
    z-index: 1;
    position: relative;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: $darkBackground;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: $defaultText;
    width: 100%;
    font: bold 30px arial;
}

.text {
    pointer-events: none;
    z-index: 1;
    display: flex;
    position: absolute;
    color: $darkBackground;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $defaultText;
    border-radius: 10px;
    width: 55%;
    margin-left: 5%;
    font: bold 30px arial;
}

.icon {
    pointer-events: all;
    position: absolute;
    width: 10%;
    left: 0;
    top: 100%;
}

.video {
    z-index: 1;
    position: absolute;
    left: 70%;
    top: 20%;
}

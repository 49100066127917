.root {
    display: flex;
    position: relative;
}

.imageContainer {
    position: absolute;
    width: 100%;
    text-align: center;
}

.image {
    border-radius: 5px;
    position: absolute;
    width: 20%;
}

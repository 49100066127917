@import '../../colors.scss';

.title {
    pointer-events: none;
    z-index: 1;
    position: relative;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: $darkBackground;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: $defaultText;
    width: 100%;
    font: bold 45px arial;
    margin-top: 7%;
    margin-bottom: 0;
}

.text {
    z-index: 1;
    display: flex;
    position: relative;
    color: $darkBackground;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: $defaultText;
    width: 100%;
    font: bold 30px arial;
}

.icon {
    position: absolute;
    width: 6.5vh;
    top: 96%;
    left: 20%;
}

.videoContainer {
    position: relative;
    width: 100%;
    height: 50vh;
}

.video {
    width: 80%;
    height: 80%;
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
}

@import '../../colors.scss';

.container {
    position: relative;
    margin-top: 20%;
    width: 100%;
    justify-content: center;
    text-align: center;
    align-items: center;
    height: 100vh;
}

.receiveMessage {
    background-color: $darkBackground;
    color: $defaultText;
    font-style: italic;
    font-size: 1em;
    border-radius: 1vh;
    z-index: -1;
    padding: 0.5em 1em;
    align-self: center;
    position: fixed;
    display: flex;
    top: 5%;
    left: 5%;
    margin-top: 0;
}

.title {
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 20%;
}

.letter {
    display: inline-block;
    text-transform: uppercase;
    font-size: 2em;
    font-weight: 700;
    font-style: italic;
    color: $defaultText;
    width: 1em;
}

.line {
    border-bottom: 0.7vh solid $defaultText;
}

form {
    width: 50%;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    font-size: 16px;
    color: $defaultText;
    font-size: 3vh;

    input[type='text'] {
        width: 100%;
        outline: none;
        border-radius: 5px;
        border: 1px solid rgb(220, 220, 220);
        color: $defaultText;
        font-size: 3vh;

        &:focus {
            border: 2px solid rgba(0, 206, 158, 1);
        }
    }
    input[type='email'] {
        width: 100%;
        outline: none;
        border-radius: 5px;
        border: 1px solid rgb(220, 220, 220);
        color: $defaultText;
        font-size: 3vh;

        &:focus {
            border: 2px solid rgba(0, 206, 158, 1);
        }
    }

    textarea {
        max-width: 100%;
        min-width: 100%;
        width: 100%;
        max-height: 30vh;
        min-height: 30vh;
        outline: none;
        border-radius: 5px;
        color: $defaultText;
        font-size: 3vh;
        resize: none;
        border: 1px solid rgb(220, 220, 220);
        font: Arial;

        &:focus {
            border: 2px solid rgb(0, 206, 158);
        }
    }

    label {
        margin-top: 1rem;
    }

    input[type='submit'] {
        margin-top: 2rem;
        padding: 0.3em;
        cursor: pointer;
        width: 50%;
        font-size: 3vh;
        background: $darkBackground;
        color: white;
        border: none;
        color: $defaultText;
        &:hover {
            border: 2px solid rgb(0, 206, 158);
        }
    }
}

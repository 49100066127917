.menu-enter {
    opacity: 0;
}

.menu-enter-active {
    opacity: 1;
    transition: all 500ms;
}

.menu-exit {
    opacity: 1;
}

.menu-exit-active {
    opacity: 0;
    transition: opacity 500ms;
}

@import '../../../colors.scss';

.container {
    justify-content: center;
    width: 80%;
    margin-top: 10%;
    margin-left: 10%;
    margin-right: 10%;
}

.opening {
    position: relative;
    font-size: 10vh;
    font-family: 'IMPACT', Times, serif;
    font-weight: 900;
    color: #5d87e9;
    width: 100%;
}

.text {
    position: relative;
    font-size: 3vh;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 900;
    color: $defaultText;
    width: 100%;
}

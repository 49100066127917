@import './components/colors.scss';

html {
    overflow-x: hidden;
    width: 100%;
}

body {
    background-color: $background;
    overflow-x: hidden;
    width: 100%;
}

@import '../../../../colors.scss';

.word {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.letter {
    display: inline-block;
    text-transform: uppercase;
    font-size: 2em;
    font-weight: 700;
    font-style: italic;
    color: $defaultText;
    width: 1em;
}

.underline {
    border-bottom: 1vh solid $defaultText;
}

@import '../colors.scss';

.bar {
    width: 100%;
    height: 5vh;
    display: flex;
    justify-content: flex-end;
    position: fixed;
    top: 0;
    margin-top: 0;
    z-index: 5;
}

.navList {
    padding-top: 0.6rem;
    padding-left: 0.6rem;
    max-width: 100%;
    height: 100%;
    display: flex;
}

.container {
    display: flex;
    justify-content: flex-end;
}

.menuIconContainer {
    max-width: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menuIcon {
    width: 45px;
}

.itemContainer {
    max-width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.itemIcon {
    width: 100%;
    height: 100%;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

a {
    text-decoration: none;
}

Link {
    text-decoration: none;
}
